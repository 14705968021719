// material-ui
import {Theme} from '@mui/material/styles';
import {Box, Button, Stack, Tooltip, Typography, useMediaQuery} from '@mui/material';

// project import
import {LogoutOutlined} from "@ant-design/icons";
import {useAuth} from "react-oidc-context";
import {useIntl} from "react-intl";

import StepexLogo from "../../../../assets/images/analytics/stepex.png";
import { useLocation } from 'react-router-dom';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {

  const intl = useIntl();
  const logoutText = intl.formatMessage({id: 'header.logout'});

  const matchesXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const auth = useAuth();

  const handleLogout = async () => {
    try {
      auth.signoutRedirect();
    } catch (err) {
      console.error(err);
    }
  };

  const location = useLocation();

  return (
      <>
        {location.pathname === '/application' && (
        <Stack spacing={1} direction="row" alignItems="self-start">
          <img alt="reader" src={StepexLogo} height="44"/>
          <Typography variant="h5" sx={{color: '#ff4d4f'}}><small>Beta</small></Typography>
        </Stack>
      )}
        <Box sx={{width: '100%', ml: 1}}/>
        <Tooltip title={logoutText}>
          <Button
              size="large"
              sx={{color: 'text.primary'}}
              startIcon={<LogoutOutlined/>}
              onClick={handleLogout}>
            {logoutText}
          </Button>
        </Tooltip>
      </>
  );
};

export default HeaderContent;
