// material-ui
import { useTheme } from "@mui/material/styles";

// project import
import DrawerHeaderStyled from "./DrawerHeaderStyled";
import StepexLogo from "../../../../assets/images/analytics/stepex.png";
import StepexLogoSymbol from "../../../../assets/images/analytics/stepex-symbol.png";
import { Stack, Typography } from "@mui/material";

import { Link } from "react-router-dom";

// ==============================|| DRAWER HEADER ||============================== //

interface Props {
  open: boolean;
}

const DrawerHeader = ({ open }: Props) => {
  const theme = useTheme();

  return (
    <DrawerHeaderStyled theme={theme} open={open}>
      {open && (
        <Stack spacing={1} direction="row" alignItems="self-start" marginTop={0.4}>
          <Link to={"/home"}>
            <img alt="reader" src={StepexLogo} height="44" />
          </Link>
          <Typography variant="h5" sx={{ color: "#ff4d4f" }}>
            <small>Beta</small>
          </Typography>
        </Stack>
      )}
      {!open && (
        <Link to={"/home"}>
          {" "}
          <img alt="reader" src={StepexLogoSymbol} height="35" style={{ marginTop: "6px" }} />{" "}
        </Link>
      )}
    </DrawerHeaderStyled>
  );
};

export default DrawerHeader;
