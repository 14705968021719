import invoices from './invoices';
import { NavItemType } from 'types/menu';
import home from './home';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [home, invoices]
};

export default menuItems;
