// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { HomeTwoTone } from '@ant-design/icons';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
    HomeTwoTone
};

// ==============================|| MENU ITEMS - INVOICES ||============================== //

const home: NavItemType = {
  id: 'group-home',
  type: 'group',
  children: [
    {
      id: 'home',
      title: <FormattedMessage id="Home" />,
      type: 'item',
      icon: icons.HomeTwoTone,
      url: '/home',
      breadcrumbs: false
    }
  ]
};

export default home;