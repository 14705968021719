import {KEYCLOAK_CONFIG} from "../../config";
import {User} from "oidc-client-ts";
import jwt_decode from "jwt-decode";

export const getAccessToken = (): string => {
  const oidcStorage = localStorage.getItem(`oidc.user:${KEYCLOAK_CONFIG.authority}:${KEYCLOAK_CONFIG.client_id}`)
  if (!oidcStorage) {
    console.warn("No user in storage")
    return "";
  }
  const user = User.fromStorageString(oidcStorage);
  return user.access_token;
};

const getDecodedToken = (): any => {
  const token = getAccessToken();
  return jwt_decode<any>(token);
};

export const getUid = (): string => {
  return getDecodedToken()?.stepex?.uid || "";
};

export const getPredefinedInstitution = (): string => {
  return getDecodedToken()?.stepex?.institution || "";
};

export const getClientRoles = (user: User | null | undefined): string[] => {
  const token = user?.access_token;
  if (!token) {
    return [];
  }
  const decodedToken = jwt_decode<any>(token);
  return decodedToken?.resource_access?.[KEYCLOAK_CONFIG.client_id]?.roles || [];
}
