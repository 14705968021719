// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { PoundCircleTwoTone } from '@ant-design/icons';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  PoundCircleTwoTone
};

// ==============================|| MENU ITEMS - INVOICES ||============================== //

const invoices: NavItemType = {
  id: 'group-invoices',
  type: 'group',
  children: [
    {
      id: 'invoices',
      title: <FormattedMessage id="Invoices" />,
      type: 'item',
      icon: icons.PoundCircleTwoTone,
      url: '/invoices',
      breadcrumbs: false
    }
  ]
};

export default invoices;
