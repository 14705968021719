// material-ui
import {Link, Stack, Typography} from '@mui/material';
import {useIntl} from "react-intl";

const getYear = () => {
  return new Date().getFullYear();
}

const Footer = () => {

  const intl = useIntl();

  return (
      <Stack direction="row" justifyContent="space-between" alignItems="center"
             sx={{p: '24px 16px 0px', mt: 'auto'}}>
        <Typography variant="caption">&copy; {getYear()}, StepEx Lender Limited</Typography>
        <Stack spacing={1.5} direction="row" justifyContent="space-between" alignItems="center">
          <Link href="https://www.stepex.co/about" target="_blank" variant="caption"
                color="textPrimary">
            {intl.formatMessage({id: "footer.about-us"})}
          </Link>
          <Link href="https://app.privasee.co.uk/privacy-center/61979d2f505e3e0013f1aaec"
                target="_blank" variant="caption" color="textPrimary">
            {intl.formatMessage({id: "footer.privacy"})}
          </Link>
          <Link href="https://www.stepex.co/our-product" target="_blank" variant="caption"
                color="textPrimary">
            {intl.formatMessage({id: "footer.our-product"})}
          </Link>
        </Stack>
      </Stack>
  );
};

export default Footer;
