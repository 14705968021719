// types
import {DefaultConfigProps} from 'types/config';
import {WebStorageStateStore} from "oidc-client-ts";
import * as process from "process";

export const drawerWidth = 260;

export const twitterColor = '#1DA1F2';
export const facebookColor = '#3b5998';
export const linkedInColor = '#0e76a8';

export const STATUS_POLLING_INTERVAL_IN_MS = parseInt(process.env.REACT_APP_STATUS_POLLING_INTERVAL_IN_MS!);

console.log(`STATUS_POLLING_INTERVAL_IN_MS = ${STATUS_POLLING_INTERVAL_IN_MS}`);

const KEYCLOAK_URL = process.env.REACT_APP_KEYCLOAK_URL;
const KEYCLOAK_CLIENT_ID = process.env.REACT_APP_KEYCLOAK_CLIENT_ID;
const KEYCLOAK_REDIRECT_URL = process.env.REACT_APP_KEYCLOAK_REDIRECT_URL;

export const KEYCLOAK_CONFIG = {
  authority: KEYCLOAK_URL!,
  client_id: KEYCLOAK_CLIENT_ID!,
  redirect_uri: KEYCLOAK_REDIRECT_URL!,
  userStore: new WebStorageStateStore({store: window.localStorage}),
}

export const JWT_API = {
  secret: 'SECRET-KEY',
  timeout: '1 days'
};

// ==============================|| THEME CONFIG  ||============================== //

const config: DefaultConfigProps = {
  defaultPath: '/home',
  fontFamily: `'Public Sans', sans-serif`,
  i18n: 'en',
  miniDrawer: false,
  container: true,
  mode: 'light',
  presetColor: 'default',
  themeDirection: 'ltr'
};

export default config;
