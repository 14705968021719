import {RoleGuardProps} from 'types/auth';
import {useAuth} from "react-oidc-context";
import {useEffect} from "react";
import {getClientRoles} from "../../services/auth/OidcService";

const RolesGuard = ({roles = [], children}: RoleGuardProps) => {

  const {isAuthenticated, signinRedirect, isLoading, user} = useAuth();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      const urlParams = new URLSearchParams(window.location.search);
      let extraParams: any = {};
      urlParams.forEach((value, key) => {
        extraParams[key] = value;
      });
      const currentUrl = window.location.href;
      signinRedirect({redirect_uri: currentUrl, extraQueryParams: extraParams});
    }
  }, [isAuthenticated, isLoading, signinRedirect]);

  if (!isAuthenticated) {
    return null;
  }
  if (roles) {
    let clientRoles = getClientRoles(user);
    const hasRoles = roles.every((role) => clientRoles.includes(role));
    if (!hasRoles) {
      console.warn('[RoleGuard]: user is not authorized for roles=', roles)
      return (null);
    }
  }
  return children;
};

export default RolesGuard;
